/* eslint-disable no-nested-ternary */
import {
  BackwardFilled,
  ForwardFilled,
  LoadingOutlined,
  SaveOutlined
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  React,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import dayjs from 'dayjs';
import { getAuth } from 'firebase/auth';
import { getDatabase, off, onValue, ref } from 'firebase/database';
import { getType } from 'mime';
import { useEffect, useState } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as AfternoonImage } from '../../assets/slots/afternoon.svg';
import { ReactComponent as EveningImage } from '../../assets/slots/evening.svg';
import { ReactComponent as MorningImage } from '../../assets/slots/morning.svg';
import { ReactComponent as UploadImage } from '../../assets/upload.svg';
import {
  getFileSignedUrl,
  postUploadPhoto
} from '../../common/common-repository';
import CountryCodePicker from '../../common/components/CountryCodePicker';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import {
  addDays,
  getDateIntoDDMMYYYY,
  getDifferenceInDays
} from '../../common/components/common-components';
import config from '../../common/config';
import { DEBOUNCE_TIME, ROUTES } from '../../common/constants';
import firebaseConfig from '../../common/firebase-config';
import {
  clearSearchField,
  convertPercentageToOriginalPrice,
  formValidatorRules,
  formatDate,
  fullDateFormat
} from '../../common/utils';
import { getRemoteConfigValue } from '../configurations/configurations-repository';
import {
  getCustomerAddressesAsAdmin,
  getCustomerPatients,
  getSlotsByDay
} from '../reports/reports-repository';
import { getAddressTemplates } from '../template-address/template-address-repository';
import { getLiveTestsAndPackages } from '../tests/test-repository';
import './customer-detail.less';
import {
  addTestToCustomerCart,
  applyOfferAsAdmin,
  clearCustomerCart,
  createCustomerAddressAsAdmin,
  createPatientAsAdmin,
  getCustomerById,
  getCustomerCartAsAdmin,
  getLabsFromTestsAsAdmin,
  getOfferCouponsAsAdmin,
  placeOrderAsAdmin,
  removeAddressAsAdmin,
  removePatientAsAdmin,
  toggleCustomerEnable,
  toggleDeliveryAtHomeAsAdmin,
  toggleUseCoinsAsAdmin,
  updateCustomerAddressAsAdmin,
  updateCustomerAsAdmin,
  updatePatientAsAdmin
} from './customers-repository';

function CustomerDetail() {
  const { Panel } = Collapse;
  const { required, number } = formValidatorRules;
  const [form] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [locationForm] = Form.useForm();
  const [addPatientForm] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const delayedMinutes = process.env.REACT_APP_TIMER_DELAY_IN_SLOTS_MINS;
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [location, setLocation] = useState();
  const [gender, setGender] = useState('MALE');
  const [openLocationPicker, setOpenLocationPicker] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isSavingAddress, setIsSavingAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isChangingAccountState, setIsChangingAccountState] = useState(false);
  const [customer, setCustomer] = useState();
  const [patients, setPatients] = useState([]);
  const [updatingPatients, setUpdatingPatients] = useState(-1);
  const [addresses, setAddresses] = useState([]);
  const [addPatientsModal, setAddPatientsModal] = useState(false);
  const [addAddressCustomer, setAddAddressCustomer] = useState(false);
  const [isPatientAdd, setIsPatientAdd] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [deletingAddress, setDeletingAddress] = useState(-1);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);
  // CART STATES
  const [selectedTests, setSelectedTests] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [cartId, setCartId] = useState('');
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);
  const [savingTests, setSavingTests] = useState(false);
  const [saveUpdate, setSaveUpdate] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // BOOKING RELATED STATES
  const [bookNowModal, setBookNowModal] = useState(false);
  const [maximumBookingPerSlot, setMaximumBookingPerSlot] = useState(0);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [slotForReschedule, setSlotForReschedule] = useState();
  const [refetchingAvailableSlots, setRefetchingAvailableSlots] =
    useState(false);
  const [isBookingOrder, setIsBookingOrder] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isHoliday, setIsHoliday] = useState(false);
  const [currentDBRef, setCurrentDBRef] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [changeAddress, setChangeAddress] = useState([]);
  const [changePatients, setChangePatients] = useState([]);
  const [changedPatientID, setChangedPatientID] = useState(null);
  const [changedAddressID, setChangedAddressID] = useState(null);
  const [offerCoupons, setOfferCoupons] = useState([]);
  const [templateAddresses, setTemplateAddresses] = useState([]);
  const [selectedLabId, setSelectedLabId] = useState(null);
  const [cartLabs, setCartLabs] = useState([]);

  const addressTypeArr = ['HOME', 'WORK', 'OTHER'];
  const [profitPerOrder, setProfitPerOrder] = useState(null);

  // PAID-UNPAID
  const [isOrderPaid, setIsOrderPaid] = useState(false);
  const [paidValue, setPaidValue] = useState('CASH');

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = [];
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        const { id } = test;
        let isAlreadySelected = false;
        for (let j = 0; j < selectedTests.length; j += 1) {
          if (selectedTests[j].id === id) {
            isAlreadySelected = true;
            break;
          }
        }
        currentFetchedTests.push({
          isSelected: isAlreadySelected,
          test
        });
      }
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      //
    }
    setIsFetchingTests(false);
  };

  const fetchLabsByCart = async (ID) => {
    try {
      const response = await getLabsFromTestsAsAdmin(ID);
      setCartLabs(response.labs);
    } catch (error) {
      //
    }
  };

  const fetchCouponsAsAdmin = async (ID) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Fetching coupons...'
    });

    try {
      await getOfferCouponsAsAdmin(ID).then((coupons) => {
        setOfferCoupons(coupons.offers);
        message.destroy(key);
      });
    } catch (error) {
      //
    }
  };

  const genderChange = (e) => {
    e.preventDefault();
    setGender(e.target.value);
  };

  const updatePatient = async (id, formValue) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Saving patient data'
    });
    const data = {
      name: formValue.name,
      dob: dayjs(formValue.dob).format('DD/MM/YYYY'),
      sex: formValue.sex,
      mobileNumber: formValue.mobileNo,
      countryCode: formValue.countryCode
    };
    await updatePatientAsAdmin(id, data);
    let selectedIndex;
    for (let index = 0; index < patients.length; index += 1) {
      const patient = patients[index];
      if (patient.id === id) {
        selectedIndex = index;
        break;
      }
    }
    const updatedPatients = patients.slice();
    updatedPatients[selectedIndex] = {
      ...updatedPatients[selectedIndex],
      ...data
    };
    setPatients(updatedPatients);
    message.open({
      key,
      type: 'success',
      content: 'Patient data updated',
      duration: 1
    });
  };

  const updateAddress = async (id, formValue, selectedIndex) => {
    if (formValue.mobileNumber < 10) {
      message.error('Invalid mobile number');
      return;
    }
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Saving address data'
    });
    const address = addresses[selectedIndex];
    const locationData = {
      address: address.location.address,
      city: address.location.city,
      latitude: address.location.latitude,
      longitude: address.location.longitude,
      pincode: address.location.pincode,
      state: address.location.state
    };

    const data = {
      addressId: id,
      data: {
        name: formValue.name,
        mobileNumber: formValue.mobileNumber,
        countryCode: formValue.countryCode,
        addressLine1: formValue.addressLine1,
        addressLine2: formValue.addressLine2,
        city: formValue.city,
        state: formValue.state,
        pincode: formValue.pincode,
        location: locationData,
        addressType: formValue.addressType
      }
    };

    await updateCustomerAddressAsAdmin(customer.id, data);
    const updatedAddresses = addresses.slice();
    updatedAddresses[selectedIndex] = {
      ...updatedAddresses[selectedIndex],
      ...data
    };
    setAddresses(updatedAddresses);
    message.open({
      key,
      type: 'success',
      content: 'Address updated',
      duration: 1
    });
  };

  const deleteAddress = async (addressId, index) => {
    setDeletingAddress(index);
    await removeAddressAsAdmin(addressId);
    const removeAddress = addresses.slice();
    removeAddress.splice(index, 1);
    setAddresses(removeAddress);
    setDeletingAddress(-1);
  };

  const onSubmitForm = async (formValue) => {
    const { name, email, dob, coinBalance } = formValue;

    if (parseFloat(coinBalance, 10) < 0) {
      message.error('Invalid balance');
      return;
    }
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Saving customer data'
    });
    setIsSavingData(true);
    await updateCustomerAsAdmin({
      customerId: customer.id,
      data: {
        avatar: uploadedImage || null,
        name,
        email: email || null,
        dob: dayjs(dob).format('DD/MM/YYYY'),
        appCoinBalance: parseFloat(coinBalance, 10) || 0,
        sex: gender
      }
    });
    // setGender('MALE');
    setIsSavingData(false);
    message.open({
      key,
      type: 'success',
      content: 'Customer data updated',
      duration: 1
    });
  };

  // BANNER UPLOAD
  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await postUploadPhoto(fileName);
    const { signedUrl, getUrl } = response;
    const request = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(request);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  const fetchAddresses = async (value) => {
    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }
    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/get-location-suggestions?search=${value}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };
    setLoadingSearchResults(true);
    const response = await axios(request);
    setPredictions(response.data.predictions);
    setLoadingSearchResults(false);
  };

  const selectAddress = async (placeId) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Location is getting selected'
    });
    const auth = getAuth();
    let userToken = '';
    const currentUser = auth?.currentUser;
    if (currentUser) {
      userToken = await currentUser.getIdToken(true);
    }
    const request = {
      method: 'get',
      url: `${config.SERVER_URL}api/decode-location-with-id?id=${placeId}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };
    const response = await axios(request);
    addressForm.setFieldsValue({
      pincode: response.data.pincode,
      state: response.data.state,
      city: response.data.city
    });
    if (selectedAddressIndex === -1) {
      setLocation(response.data);
    } else {
      const currentLocation = response.data;
      const tempAddresses = addresses.slice();
      tempAddresses[selectedAddressIndex] = {
        ...tempAddresses[selectedAddressIndex],
        location: currentLocation
      };
      setAddresses(tempAddresses);
    }
    setSelectedAddressIndex(-1);
    clearSearchField('Location');
    locationForm.resetFields();
    setOpenLocationPicker(false);
    message.open({
      key,
      type: 'success',
      content: 'Location picked',
      duration: 1
    });
  };

  const beforeUpload = (file) => {
    const isLT10MB = file.size / 1024 / 1024 <= 10;
    if (!isLT10MB) {
      message.error('Image must smaller than 10MB!');
      return !isLT10MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  // CREATE PATIENt MODAL
  const handleCancel = () => {
    addPatientForm.resetFields();
    setAddPatientsModal(false);
  };

  const handleLocationPickerCancel = () => {
    locationForm.resetFields();
    setSelectedAddressIndex(-1);
    setOpenLocationPicker(false);
    clearSearchField('Location');
  };

  const handleCancelAddress = () => {
    addressForm.resetFields();
    setLocation(undefined);
    setAddAddressCustomer(false);
  };

  // BOOKING THE ORDER CODE HERE
  const getMaximumPerson = async () => {
    try {
      const response = await getRemoteConfigValue();
      setMaximumBookingPerSlot(response?.data[0]?.value);
    } catch (error) {
      //
    }
  };

  const arrangeAvailableSlots = (daySlots, liveData, date) => {
    if (liveData && liveData.is_holiday) {
      setIsHoliday(true);
      setRefetchingAvailableSlots(false);
      setAvailableSlots([]);
      return;
    }
    setIsHoliday(false);
    const tempAvailableSlots = [];
    const tempCurrentTime = new Date();
    tempCurrentTime.setMinutes(
      tempCurrentTime.getMinutes() + parseInt(delayedMinutes, 10)
    );
    const differ =
      tempCurrentTime && date ? getDifferenceInDays(tempCurrentTime, date) : -1;
    for (let i = 0; i < daySlots.length; i += 1) {
      const daySlot = daySlots[i];
      const { id = '' } = daySlot;
      const currentCapacity = liveData.slots[id] || 0;
      let isPastTime = false;
      if (differ === 0) {
        const newTimeWhenDayStarted = new Date(
          tempCurrentTime.getFullYear(),
          tempCurrentTime.getMonth(),
          tempCurrentTime.getDate()
        );
        const diffMs = tempCurrentTime - newTimeWhenDayStarted; // milliseconds between now & Christmas
        const inSec = diffMs / 1000;
        const inMin = inSec / 60;
        isPastTime = inMin > daySlot.startTime;
      }
      if (!isPastTime) {
        const tempAvailableSlot = {
          available:
            currentCapacity <
              (daySlot.maxBookingLimit || maximumBookingPerSlot) && !isPastTime, // ADD CONDITION NOT HAVE TIME BEFORE TIME // DISABLE IF ID AND DAY SAME //
          slot: daySlot,
          selected: false
        };
        tempAvailableSlots.push(tempAvailableSlot);
      }
    }
    setRefetchingAvailableSlots(false);
    setSlotForReschedule(undefined);
    setAvailableSlots(tempAvailableSlots);
  };
  const monitorSlots = async (date) => {
    if (currentDBRef) {
      off(currentDBRef);
      setCurrentDBRef(undefined);
    }
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Fetching slots...'
    });
    const days = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ];

    const response = await getSlotsByDay({
      day: days[date.getDay()]
    });
    const allSLotsArr = [];
    const { morning, afternoon, evening } = response;

    morning.forEach((slot) => {
      allSLotsArr.push({
        ...slot,
        isMorning: true,
        isAfternoon: false,
        isEvening: false
      });
    });
    afternoon.forEach((slot) => {
      allSLotsArr.push({
        ...slot,
        isMorning: false,
        isAfternoon: true,
        isEvening: false
      });
    });
    evening.forEach((slot) => {
      allSLotsArr.push({
        ...slot,
        isMorning: false,
        isAfternoon: false,
        isEvening: true
      });
    });

    try {
      //
      const db = getDatabase(firebaseConfig);
      const formatted = getDateIntoDDMMYYYY(date);
      const dbRef = ref(db, `availability/${formatted}`);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        const correctedData = data || { isHoliday: false, slots: [] };
        if (!correctedData.slots) {
          correctedData.slots = [];
        }
        arrangeAvailableSlots(allSLotsArr, correctedData, date);
      });
      setCurrentDBRef(dbRef);
    } catch (error) {
      //
    }
  };

  // ADD PATIENT
  const addPatient = async (formValue) => {
    const { patientName, patientDob, patientSex, mobileNo, countryCode } =
      formValue;
    const data = {
      name: patientName,
      // eslint-disable-next-line radix
      dob: dayjs(patientDob).format('DD/MM/YYYY'),
      sex: patientSex || gender,
      mobileNumber: mobileNo,
      countryCode: countryCode
    };
    setIsPatientAdd(true);
    const response = await createPatientAsAdmin(customer.id, data);
    const createPatients = patients.slice();
    createPatients.push(response.patient);
    setPatients(createPatients);
    setAddPatientsModal(false);
    addPatientForm.resetFields();
    setGender('MALE');
    setIsPatientAdd(false);
  };

  // DELETE PATIENT
  const deletePatient = async (pId, index) => {
    setUpdatingPatients(index);
    await removePatientAsAdmin(pId);
    const removedPatients = patients.slice();
    removedPatients.splice(index, 1);
    setPatients(removedPatients);
    setUpdatingPatients(-1);
  };

  // DISABLE ENABLE
  const disableEnableCustomer = async () => {
    setIsChangingAccountState(true);
    await toggleCustomerEnable(customer.id);
    setCustomer({
      ...customer,
      isDisabled: !customer.isDisabled
    });
    setIsChangingAccountState(false);
  };

  const copyToClipboard = (copiedText) => {
    // eslint-disable-next-line no-undef
    const tempInput = document.createElement('input');
    tempInput.value = copiedText;
    // eslint-disable-next-line no-undef
    document.body.appendChild(tempInput);
    tempInput.select();
    // eslint-disable-next-line no-undef
    document.execCommand('copy');
    // eslint-disable-next-line no-undef
    document.body.removeChild(tempInput);
    message.success('Code copied!');
  };

  // ADD NEW ADDRESS
  const addNewAddress = async (formValue) => {
    if (!location) {
      message.error('Location is not selected');
      return;
    }
    if (formValue.mobileNumber < 0) {
      message.error('Invalid number');
      return;
    }
    if (formValue.pincode < 0) {
      message.error('Invalid pincode');
      return;
    }
    const locationData = {
      address: location.address,
      city: location.city,
      latitude: location.latitude,
      longitude: location.longitude,
      pincode: location.pincode,
      state: location.state
    };

    const data = {
      name: formValue.name,
      mobileNumber: formValue.mobileNumber,
      countryCode: formValue.countryCode || '91',
      addressLine1: formValue.addressLine1,
      addressLine2: formValue.addressLine2,
      city: formValue.city,
      state: formValue.state,
      pincode: formValue.pincode,
      location: locationData,
      addressType: formValue.addressType
    };
    setIsSavingAddress(true);
    const response = await createCustomerAddressAsAdmin(customer.id, data);
    if (response === false) {
      setIsSavingAddress(false);
      return;
    }
    const createAddresses = addresses.slice();
    createAddresses.push(response.address);
    setAddresses(createAddresses);
    addressForm.resetFields();
    setLocation(undefined);
    setIsSavingAddress(false);
    setAddAddressCustomer(false);
  };

  const getCustomerCartDetails = (customerId) => {
    getCustomerCartAsAdmin(customerId).then((fetchSelectedTests) => {
      setSelectedTests(fetchSelectedTests.cart.tests);
      setCartDetails(fetchSelectedTests.cart);
      setCartId(fetchSelectedTests.cart.id);
    });
  };

  const saveAddedCartTests = async () => {
    setSavingTests(true);
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Saving tests'
    });
    const customerId = searchParams.get('customerId');
    const selectedIds = [];
    for (let i = 0; i < selectedTests.length; i += 1) {
      selectedIds.push(selectedTests[i].id);
    }
    await addTestToCustomerCart({
      customerId,
      tests: selectedIds
    });
    getCustomerCartDetails(customerId);
    message.open({
      key,
      type: 'success',
      content: 'Done'
    });
    setSavingTests(false);
    setSaveUpdate(false);
  };
  const fetchAddress = async (ID) => {
    const addressResponse = await getCustomerAddressesAsAdmin(
      {
        skip: 0,
        limit: 10000
      },
      ID
    );
    setChangeAddress(addressResponse);
  };
  const fetchPatient = async (ID) => {
    const response = await getCustomerPatients(ID, {
      skip: 0,
      limit: 10000
    });
    setChangePatients(response.patients);
  };

  const addressOptions = [];
  for (let i = 0; i < changeAddress.length; i += 1) {
    addressOptions.push({
      value: changeAddress[i].id,
      label: `${changeAddress[i].name},${changeAddress[i].addressLine1},${changeAddress[i].addressLine2},${changeAddress[i].city},${changeAddress[i].pincode},${changeAddress[i].state} `
    });
  }

  const patientsSelectData = [];
  for (let i = 0; i < changePatients.length; i += 1) {
    patientsSelectData.push({
      value: changePatients[i].id,
      label: changePatients[i].name
    });
  }

  const labsOption = [];
  for (let i = 0; i < cartLabs.length; i += 1) {
    labsOption.push({
      value: cartLabs[i].id,
      label: cartLabs[i].displayName
    });
  }

  const handleCancelBooking = () => {
    setChangedAddressID(null);
    setChangedPatientID(null);
    setSelectedLabId(null);
    setProfitPerOrder(null);
    setIsOrderPaid(false);
    setIsVisible(false);
    setPaidValue('CASH');
    setBookNowModal(false);
  };

  const fetchCustomerById = async (ID) => {
    await getCustomerById(ID).then((fetchedCustomer) => {
      setCustomer(fetchedCustomer);
      // eslint-disable-next-line no-console
      console.log({ fetchedCustomer });
      setPatients(fetchedCustomer.patients || []);
      setAddresses(fetchedCustomer.addresses || []);
      form?.setFieldsValue({
        name: fetchedCustomer.name,
        coinBalance: (fetchedCustomer.wallet || { balance: 0 }).balance.toFixed(
          2
        ),
        email: fetchedCustomer.email,
        dob: dayjs(fetchedCustomer.dob, 'DD/MM/YYYY'),
        mobileNo: fetchedCustomer.mobileNumber,
        countryCode: fetchedCustomer.countryCode
      });
      setGender(fetchedCustomer.sex);
      setUploadedImage(fetchedCustomer.avatar);
      setIsLoading(false);
    });
  };

  let discountValue = 0;
  let totalTestPrice = 0;
  let totalDiscountAmt = 0;

  for (let i = 0; i < selectedTests.length; i += 1) {
    let priceBeforeDiscount = 0;
    const test = selectedTests[i];
    if (test.kind === 'PERCENTAGE') {
      priceBeforeDiscount = convertPercentageToOriginalPrice(
        test.price,
        test.discount
      );
      discountValue = parseFloat(priceBeforeDiscount - test.price);
    } else {
      discountValue += parseFloat(test.discount);
    }
    totalTestPrice += parseFloat(test.price + discountValue);
    totalDiscountAmt += parseFloat(discountValue);
  }

  // FETCHING TEMPLATE ADDRESSES
  const fetchAddressTemplates = async () => {
    try {
      const response = await getAddressTemplates({
        skip: 0,
        limit: 100000
      });

      setTemplateAddresses(response.templateAddresses);
    } catch (error) {
      //
    }
  };

  const templateOptions = [];
  for (let i = 0; i < templateAddresses.length; i += 1) {
    templateOptions.push({
      value: templateAddresses[i].id,
      label: `${templateAddresses[i].addressLine1},${templateAddresses[i].addressLine2},${templateAddresses[i].city},${templateAddresses[i].location.pincode},${templateAddresses[i].state} `
    });
  }

  // USE EFFECT
  useEffect(() => {
    const customerId = searchParams.get('customerId');
    fetchCustomerById(customerId);
    getMaximumPerson();
    getCustomerCartDetails(customerId);
    fetchAddressTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Example initialValues object (replace this with your actual initialValues)
  const initialValues = {
    name: customer?.name, // Replace with your default value for 'name'
    mobileNumber: customer?.mobileNumber // Replace with your default value for 'mobileNumber'
    // Add other initial values as needed
  };

  const displayGender = (sex) => {
    // eslint-disable-next-line default-case
    switch (sex) {
      case 'MALE':
        return 'M';
      case 'FEMALE':
        return 'F';
      case 'OTHER':
        return 'O';
    }
  };

  const displayTitle = () => {
    if (customer) {
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          {customer.name}, {displayGender(customer.sex)} <div className="dot" />{' '}
          {customer.age}
        </span>
      );
    }
    return 'Customer';
  };

  const calculateProfitPerOrder = (labId) => {
    const { tests, subTotal } = cartDetails;
    let overallLabCost = 0;
    for (let i = 0; i < tests.length; i += 1) {
      const test = tests[i];
      const matchingCost = test.productionCostWRTLab.find(
        (item) => item.lab.id === labId
      );
      const labCost = matchingCost.productionCost;
      overallLabCost += labCost;
    }
    const profitCalculated = subTotal - overallLabCost;
    setProfitPerOrder(profitCalculated.toFixed(1));
  };

  const handleVisibleValue = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Space>
              <Typography.Text className="main-header-title">
                {displayTitle()}
              </Typography.Text>
            </Space>
          </Space>
          <div
            style={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <Button
              className="orderHistory"
              type="default"
              onClick={() => {
                const url = `${ROUTES.CUSTOMER_APP_NOTIFICATIONS}?customerId=${customer.id}`;
                navigate(url);
              }}
            >
              View app notifications
            </Button>
            <Button
              className="orderHistory"
              type="default"
              onClick={() => {
                const url = `${ROUTES.CUSTOMER_ORDER_HISTORY}?customerId=${customer.id}`;
                navigate(url);
              }}
            >
              View order history
            </Button>
          </div>
        </div>
      </Header>
      <div
        className="main-content customer-detail"
        style={{ backgroundColor: '#F2F4F7' }}
      >
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <Row>
            <Col span={18} style={{ paddingRight: '24px' }}>
              <div className="card user-basic-form">
                <Form form={form} onFinish={onSubmitForm} layout="vertical">
                  <Form.Item name="upload">
                    <div className="profile">
                      <Upload
                        name="upload-icon"
                        onChange={onIconUpload}
                        accept="image/*"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                      >
                        {uploadedImage && !uploadingImage && (
                          <img
                            className="uploaded-image"
                            src={uploadedImage}
                            alt="xyz"
                          />
                        )}
                        {!uploadedImage && !uploadingImage && (
                          <UploadImage className="sample-image" />
                        )}
                        {uploadingImage && <LoadingOutlined />}
                        {uploadedImage ? (
                          <p>Re-upload image</p>
                        ) : (
                          <p>Upload image</p>
                        )}
                      </Upload>
                    </div>
                  </Form.Item>
                  <Row className="row-one">
                    <Col span={12} className="left">
                      <Form.Item rules={[required]} name="name" label="Name">
                        <Input type="text" />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="right">
                      <Form.Item name="coinBalance" label="App coin balance">
                        <InputNumber controls={false} type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="row-one">
                    <Col span={12} className="left">
                      <Form.Item name="email" label="Email id">
                        <Input type="email" />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="right">
                      <Form.Item
                        rules={[required]}
                        className="mobile-number"
                        name="mobileNo"
                        label="Mobile number"
                      >
                        <Input
                          type="text"
                          disabled
                          addonBefore={
                            <CountryCodePicker isDisabled name="countryCode" />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="row-one">
                    <Col span={12} className="left">
                      <Form.Item rules={[required]} name="dob" label="D.O.B">
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="right">
                      <p className="gender"> Sex</p>
                      <Radio.Group
                        rules={[required]}
                        onChange={genderChange}
                        value={gender}
                      >
                        <Radio value="MALE">Male</Radio>
                        <Radio value="FEMALE">Female</Radio>
                        <Radio value="OTHER"> Other</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>

                  <Divider />
                  <Button
                    loading={isSavingData}
                    htmlType="submit"
                    className="create-btn"
                    style={{ left: 0 }}
                  >
                    Save
                  </Button>
                </Form>
              </div>
              <div style={{ height: '24px' }} />
              <div className="card user-basic-form">
                <div className="title">Patients</div>
                {/* <Divider /> */}
                <Space direction="vertical" style={{ width: '100%' }}>
                  {patients.map((patient, index) => {
                    const patientId = `patient-${index}`;
                    if (updatingPatients === index) {
                      return <div key={patientId} />;
                    }
                    return (
                      <Collapse
                        bordered={false}
                        key={patientId}
                        collapsible="header"
                      >
                        <Panel
                          header={`Patient ${index + 1} - ${patient.name}`}
                          key="1"
                        >
                          <Form
                            layout="vertical"
                            key={patientId}
                            onFinish={(formValue) => {
                              updatePatient(patient.id, formValue);
                            }}
                          >
                            <div
                              key={patient.id || index}
                              className="patient-container"
                            >
                              <Row className="row-one">
                                <Col span={12} className="left">
                                  <Form.Item
                                    rules={[required]}
                                    name="name"
                                    label="Name"
                                    initialValue={patient.name}
                                  >
                                    <Input type="text" />
                                  </Form.Item>
                                </Col>
                                <Col span={12} className="right">
                                  <Form.Item
                                    rules={[required]}
                                    name="dob"
                                    label="D.O.B"
                                    initialValue={dayjs(
                                      patient.dob,
                                      'DD/MM/YYYY'
                                    )}
                                  >
                                    <DatePicker format="DD/MM/YYYY" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row className="row-one">
                                <Col span={12} className="right">
                                  <Form.Item
                                    className="mobile-number"
                                    name="mobileNo"
                                    label="Mobile number"
                                    initialValue={patient.mobileNumber}
                                    rules={[
                                      number,
                                      () => ({
                                        validator(rule, value) {
                                          if (value.length > 10) {
                                            // eslint-disable-next-line prefer-promise-reject-errors
                                            return Promise?.reject(
                                              'Number limit exceeded'
                                            );
                                          }

                                          return Promise?.resolve();
                                        }
                                      })
                                    ]}
                                  >
                                    <Input
                                      type="text"
                                      addonBefore={
                                        <CountryCodePicker
                                          name="countryCode"
                                          initialValue={patient.countryCode}
                                        />
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12} className="right">
                                  <p className="gender"> Sex</p>
                                  <Form.Item
                                    name="sex"
                                    initialValue={patient.sex}
                                  >
                                    <Radio.Group rules={[required]}>
                                      <Radio value="MALE">Male</Radio>
                                      <Radio value="FEMALE">Female</Radio>
                                      <Radio value="OTHER"> Other</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <div className="buttons">
                                <Popconfirm
                                  title="Delete"
                                  description="Are you sure to delete this patient?"
                                  onConfirm={() => {
                                    deletePatient(patient.id, index);
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="text">
                                    <Space className="delete-button">
                                      <Trash className="patient-icon" />
                                      <span className="patient-title">
                                        Delete patient
                                      </span>
                                    </Space>
                                  </Button>
                                </Popconfirm>
                                <Button type="text" htmlType="submit">
                                  <Space className="delete-button">
                                    <SaveOutlined />
                                    <span className="patient-title">Save</span>
                                  </Space>
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </Panel>
                      </Collapse>
                    );
                  })}
                  <Divider dashed />
                  <Button
                    type="dashed"
                    className="add-patient"
                    onClick={() => {
                      setAddPatientsModal(true);
                    }}
                  >
                    + Add another patient
                  </Button>
                </Space>
                <Modal
                  open={openLocationPicker}
                  onCancel={handleLocationPickerCancel}
                  centered
                  footer={null}
                  title="Pick location"
                >
                  <Form form={locationForm}>
                    <SearchComponent
                      name="Location"
                      debounceTime={DEBOUNCE_TIME}
                      getData={(value) => {
                        fetchAddresses(value);
                      }}
                      style={{
                        height: '45px',
                        borderRadius: '10px'
                      }}
                    />
                    {loadingSearchResults ? (
                      <div className="empty-state">
                        <LoaderComponent />
                      </div>
                    ) : predictions.length === 0 ? (
                      <Empty className="empty-state" />
                    ) : (
                      <div className="fixed-height">
                        {predictions.map((prediction, index) => {
                          const predictionId = `prediction-${index}`;
                          return (
                            <div
                              className="location-container"
                              key={predictionId}
                              onClick={() => {
                                const { place_id: placeId } = prediction;
                                selectAddress(placeId);
                              }}
                            >
                              {prediction.description}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Form>
                </Modal>
                {/* ADD PATIENT MODAL */}
                <Modal
                  open={addPatientsModal}
                  onCancel={handleCancel}
                  className="create-patient-modal"
                  centered
                  title="Add Patient"
                >
                  <Divider />
                  <Form
                    form={addPatientForm}
                    onFinish={addPatient}
                    className="equipment-form"
                    layout="vertical"
                  >
                    <div className="equipment-form-control">
                      <Form.Item
                        rules={[required]}
                        name="patientName"
                        label="Patient name"
                      >
                        <Input
                          className="inp-equiment"
                          name="equipment"
                          type="text"
                          placeholder="Add patient name"
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[required]}
                        name="patientDob"
                        label="D.O.B"
                      >
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </div>
                    <div className="equipment-form-control">
                      <Form.Item
                        className="mobile-number"
                        name="mobileNo"
                        label="Mobile number"
                      >
                        <Input
                          type="text"
                          addonBefore={<CountryCodePicker name="countryCode" />}
                        />
                      </Form.Item>

                      <Form.Item name="patientSex" label="Sex">
                        <Radio.Group rules={[required]} defaultValue="MALE">
                          <Radio value="MALE">Male</Radio>
                          <Radio value="FEMALE">Female</Radio>
                          <Radio value="OTHER"> Other</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>

                    <Divider />
                    <div className="create-wrapper">
                      <Button
                        loading={isPatientAdd}
                        htmlType="submit"
                        className="add-patient-btn"
                      >
                        Add Patient
                      </Button>
                    </div>
                  </Form>
                </Modal>
              </div>
              <div
                style={{
                  height: '24px'
                }}
              />
              <div className="card user-basic-form">
                <div className="title">Address</div>
                <Space direction="vertical" style={{ width: '100%' }}>
                  {addresses.map((address, index) => {
                    const addressId = `address-${index}`;
                    if (deletingAddress === index) {
                      return <div key={addressId} />;
                    }
                    return (
                      <Collapse
                        bordered={false}
                        key={addressId}
                        collapsible="header"
                      >
                        <Panel
                          header={`Patient ${index + 1} - ${address.name}`}
                          key="1"
                        >
                          <Form
                            layout="vertical"
                            key={addressId}
                            onFinish={(formValue) => {
                              updateAddress(address.id, formValue, index);
                            }}
                          >
                            <div
                              key={address.id || index}
                              className="patient-container"
                            >
                              <Row className="row-one">
                                <Col span={12} className="left">
                                  <Form.Item
                                    rules={[required]}
                                    name="name"
                                    label="Name"
                                    initialValue={address.name}
                                  >
                                    <Input type="text" />
                                  </Form.Item>
                                </Col>
                                <Col span={12} className="right">
                                  <Form.Item
                                    rules={[required]}
                                    className="mobile-number"
                                    name="mobileNumber"
                                    initialValue={address.mobileNumber}
                                    label="Mobile number"
                                  >
                                    <Input
                                      type="text"
                                      addonBefore={
                                        <CountryCodePicker
                                          initialValue={address.countryCode}
                                          name="countryCode"
                                        />
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row className="row-one">
                                <Col span={12} className="left">
                                  <Form.Item
                                    rules={[required]}
                                    name="pincode"
                                    label="Pincode"
                                    initialValue={address.pincode}
                                  >
                                    <Input type="text" />
                                  </Form.Item>
                                </Col>
                                <Col span={12} className="right">
                                  <Form.Item
                                    rules={[required]}
                                    name="state"
                                    label="State"
                                    initialValue={address.state}
                                  >
                                    <Input type="text" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row className="row-one">
                                <Col span={12} className="left">
                                  <Form.Item
                                    rules={[required]}
                                    name="addressLine1"
                                    label="Address Line 1"
                                    initialValue={address.addressLine1}
                                  >
                                    <Input type="text" />
                                  </Form.Item>
                                </Col>
                                <Col span={12} className="right">
                                  <Form.Item
                                    rules={[required]}
                                    name="addressLine2"
                                    label="Address Line 2"
                                    initialValue={address.addressLine2}
                                  >
                                    <Input type="text" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row className="row-one">
                                <Col span={12} className="left">
                                  <Form.Item
                                    rules={[required]}
                                    name="city"
                                    label="City"
                                    initialValue={address.city}
                                  >
                                    <Input type="text" />
                                  </Form.Item>
                                </Col>
                                <Col span={12} className="right">
                                  <Form.Item label="Location address">
                                    <Button
                                      style={{
                                        width: '100%',
                                        height: '48px'
                                      }}
                                      onClick={() => {
                                        setOpenLocationPicker(true);
                                        setSelectedAddressIndex(index);
                                      }}
                                    >
                                      Change location
                                    </Button>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  span={24}
                                  className="addresstype-container"
                                >
                                  <Form.Item name="addressType">
                                    <Segmented
                                      options={addressTypeArr}
                                      defaultValue={address.addressType}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this address?"
                                onConfirm={() => {
                                  deleteAddress(address.id, index);
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  type="text"
                                  // onClick={() => deleteAddress(address.id, index)}
                                >
                                  <Space className="delete-button">
                                    <Trash className="patient-icon" />
                                    <span className="patient-title">
                                      Delete Address
                                    </span>
                                  </Space>
                                </Button>
                              </Popconfirm>
                              <Button type="text" htmlType="submit">
                                <Space className="delete-button">
                                  <SaveOutlined />
                                  <span className="patient-title">Save</span>
                                </Space>
                              </Button>
                            </div>
                          </Form>
                        </Panel>
                      </Collapse>
                    );
                  })}
                  <Divider dashed />
                  <Button
                    type="dashed"
                    className="add-patient"
                    onClick={() => {
                      setAddAddressCustomer(true);
                    }}
                  >
                    + Add another address
                  </Button>
                </Space>
                {/* ADD NEW ADDRESS MODAL */}
                <Modal
                  open={addAddressCustomer}
                  onCancel={handleCancelAddress}
                  className="create-patient-modal addressModal"
                  centered
                  title="Add Address"
                >
                  <Divider />
                  <Form
                    initialValues={initialValues}
                    layout="vertical"
                    form={addressForm}
                    onFinish={addNewAddress}
                  >
                    <div className="patient-container">
                      <Row className="row-one">
                        <Col span={12} className="left">
                          <Form.Item
                            rules={[required]}
                            name="name"
                            label="Name"
                          >
                            <Input type="text" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="right">
                          <Form.Item
                            rules={[
                              required,
                              number,
                              () => ({
                                validator(rule, value) {
                                  if (value.length > 10) {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise?.reject(
                                      'Number limit exceeded'
                                    );
                                  }
                                  return Promise?.resolve();
                                }
                              })
                            ]}
                            className="mobile-number"
                            name="mobileNumber"
                            style={{
                              padding: '0px'
                            }}
                            label="Mobile number"
                          >
                            <Input
                              type="text"
                              addonBefore={
                                <CountryCodePicker name="countryCode" />
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="row-one">
                        <Col span={12} className="left">
                          <Form.Item
                            rules={[required]}
                            name="pincode"
                            label="Pincode"
                          >
                            <Input type="text" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="right">
                          <Form.Item
                            rules={[required]}
                            name="state"
                            label="State"
                          >
                            <Input type="text" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="row-one">
                        <Col span={12} className="left">
                          <Form.Item
                            rules={[required]}
                            name="addressLine1"
                            label="Address Line 1"
                          >
                            <Input type="text" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="right">
                          <Form.Item
                            rules={[required]}
                            name="addressLine2"
                            label="Address Line 2"
                          >
                            <Input type="text" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="row-one">
                        <Col span={12} className="left">
                          <Form.Item
                            rules={[required]}
                            name="city"
                            label="City"
                          >
                            <Input type="text" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="right">
                          <Form.Item label="Location address">
                            <Button
                              style={{
                                width: '100%',
                                height: '48px'
                              }}
                              onClick={() => {
                                setOpenLocationPicker(true);
                              }}
                            >
                              {location ? 'Change location' : 'Select location'}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        className="row-one"
                        style={{
                          paddingBottom: '10px'
                        }}
                      >
                        <Col span={24} className="addresstype-container">
                          <Form.Item name="addressType">
                            <Segmented options={addressTypeArr} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <Divider />
                    <div
                      className="create-wrapper"
                      style={{
                        display: 'flex',
                        paddingTop: '10px',
                        gap: '5px'
                      }}
                    >
                      <Button
                        loading={isSavingAddress}
                        htmlType="submit"
                        className="add-patient-btn"
                      >
                        Add Address
                      </Button>
                      <Select
                        className="book-now-selection"
                        placeholder="Select address"
                        options={templateOptions}
                        value={changedAddressID}
                        onChange={(change) => {
                          const selectedAddress = templateAddresses.find(
                            (option) => option.id === change
                          );
                          const {
                            addressLine1,
                            addressLine2,
                            city,
                            location: tempLocationData
                          } = selectedAddress;
                          setLocation(tempLocationData);
                          // const data = {
                          //   name: formValue.name,
                          //   mobileNumber: formValue.mobileNumber,
                          //   countryCode: formValue.countryCode || '91',
                          //   addressLine1: formValue.addressLine1,
                          //   addressLine2: formValue.addressLine2,
                          //   city: formValue.city,
                          //   state: formValue.state,
                          //   pincode: formValue.pincode,
                          //   location:
                          //     tempLocation === null
                          //       ? locationData
                          //       : tempLocation
                          // };
                          addressForm.setFieldsValue({
                            addressLine1,
                            addressLine2,
                            city,
                            state: tempLocationData.state,
                            pincode: tempLocationData.pincode
                          });
                        }}
                      />
                    </div>
                  </Form>
                </Modal>
                {/* BOOK MODAL HERE */}
                <Modal
                  className="reschedule  modal-sizer-res"
                  title={
                    currentDate && (
                      <div className="reschedule-title-section">
                        <Button
                          type="text"
                          onClick={() => {
                            setCurrentDate((prev) => {
                              const newDate = addDays(prev, -1);
                              setSlotForReschedule(undefined);
                              monitorSlots(newDate);
                              setRefetchingAvailableSlots(true);
                              setBookNowModal(true);
                              return newDate;
                            });
                          }}
                          style={{
                            visibility:
                              currentDate &&
                              getDifferenceInDays(currentDate, new Date()) === 0
                                ? 'hidden'
                                : ''
                          }}
                        >
                          <BackwardFilled className="btn" />
                        </Button>
                        <Space className="warning-container">
                          <span>
                            {formatDate(currentDate.toString(), 'DD/MM/YYYY')}
                          </span>
                        </Space>
                        <Button
                          type="text"
                          onClick={() => {
                            setCurrentDate((prev) => {
                              const newDate = addDays(prev, 1);
                              setSlotForReschedule(undefined);
                              monitorSlots(newDate);
                              setRefetchingAvailableSlots(true);
                              setBookNowModal(true);
                              return newDate;
                            });
                          }}
                        >
                          <ForwardFilled className="btn" />
                        </Button>
                      </div>
                    )
                  }
                  centered
                  closable={false}
                  open={bookNowModal}
                  okButtonProps={{
                    className: 'no-display'
                  }}
                  cancelButtonProps={{ className: 'no-display' }}
                  onCancel={handleCancelBooking}
                >
                  {currentDate && (
                    <div className="reschedule-container">
                      <div className="available-times">
                        {refetchingAvailableSlots ? (
                          <Spin size="large" />
                        ) : (
                          <>
                            <div>
                              <div className="event-type">
                                <MorningImage /> Morning
                              </div>
                              <Row>
                                {availableSlots.filter(
                                  (availableSlot) =>
                                    availableSlot.slot.isMorning
                                ).length < 1
                                  ? 'No morning slots available '
                                  : availableSlots
                                      .filter(
                                        (availableSlot) =>
                                          availableSlot.slot.isMorning
                                      )
                                      .map((availableSlot) => {
                                        const { slot, selected, available } =
                                          availableSlot;
                                        return (
                                          <Col
                                            className="forHover"
                                            key={slot.id}
                                            onClick={
                                              available
                                                ? () => {
                                                    let selectedIndex = 0;
                                                    const tempAvailableSlots =
                                                      availableSlots.slice();
                                                    for (
                                                      let i = 0;
                                                      i <
                                                      tempAvailableSlots.length;
                                                      i += 1
                                                    ) {
                                                      tempAvailableSlots[i] = {
                                                        ...tempAvailableSlots[
                                                          i
                                                        ],
                                                        selected: false
                                                      };
                                                      if (
                                                        tempAvailableSlots[i]
                                                          .slot.id === slot.id
                                                      ) {
                                                        tempAvailableSlots[
                                                          i
                                                        ].selected = true;
                                                        selectedIndex = i;
                                                      }
                                                    }
                                                    setAvailableSlots(
                                                      tempAvailableSlots
                                                    );
                                                    setSlotForReschedule(
                                                      tempAvailableSlots[
                                                        selectedIndex
                                                      ]
                                                    );
                                                  }
                                                : null
                                            }
                                          >
                                            <div
                                              className={`time-slot${
                                                selected ? ' selected-slot' : ''
                                              }${
                                                available
                                                  ? ''
                                                  : ' disabled-slot'
                                              }`}
                                            >
                                              <p>{slot.name.toUpperCase()}</p>
                                            </div>
                                          </Col>
                                        );
                                      })}
                              </Row>
                            </div>
                            <div>
                              <div className="event-type">
                                <AfternoonImage /> Afternoon
                              </div>
                              <Row>
                                {availableSlots.filter(
                                  (availableSlot) =>
                                    availableSlot.slot.isAfternoon
                                ).length < 1
                                  ? 'No afternoon slots available '
                                  : availableSlots
                                      .filter(
                                        (availableSlot) =>
                                          availableSlot.slot.isAfternoon
                                      )
                                      .map((availableSlot) => {
                                        const { slot, selected, available } =
                                          availableSlot;
                                        return (
                                          <Col
                                            className="forHover"
                                            key={slot.id}
                                            onClick={
                                              available
                                                ? () => {
                                                    let selectedIndex = 0;
                                                    const tempAvailableSlots =
                                                      availableSlots.slice();
                                                    for (
                                                      let i = 0;
                                                      i <
                                                      tempAvailableSlots.length;
                                                      i += 1
                                                    ) {
                                                      tempAvailableSlots[i] = {
                                                        ...tempAvailableSlots[
                                                          i
                                                        ],
                                                        selected: false
                                                      };
                                                      if (
                                                        tempAvailableSlots[i]
                                                          .slot.id === slot.id
                                                      ) {
                                                        tempAvailableSlots[
                                                          i
                                                        ].selected = true;
                                                        selectedIndex = i;
                                                      }
                                                    }
                                                    setAvailableSlots(
                                                      tempAvailableSlots
                                                    );
                                                    setSlotForReschedule(
                                                      tempAvailableSlots[
                                                        selectedIndex
                                                      ]
                                                    );
                                                  }
                                                : null
                                            }
                                          >
                                            <div
                                              className={`time-slot${
                                                selected ? ' selected-slot' : ''
                                              }${
                                                available
                                                  ? ''
                                                  : ' disabled-slot'
                                              }`}
                                            >
                                              <p>{slot.name.toUpperCase()}</p>
                                            </div>
                                          </Col>
                                        );
                                      })}
                              </Row>
                            </div>
                            <div>
                              <div className="event-type">
                                <EveningImage /> Evening
                              </div>
                              <Row>
                                {availableSlots.filter(
                                  (availableSlot) =>
                                    availableSlot.slot.isEvening
                                ).length < 1
                                  ? 'No evening slots available '
                                  : availableSlots
                                      .filter(
                                        (availableSlot) =>
                                          availableSlot.slot.isEvening
                                      )
                                      .map((availableSlot) => {
                                        const { slot, selected, available } =
                                          availableSlot;
                                        return (
                                          <Col
                                            className="forHover"
                                            key={slot.id}
                                            onClick={
                                              available
                                                ? () => {
                                                    let selectedIndex = 0;
                                                    const tempAvailableSlots =
                                                      availableSlots.slice();
                                                    for (
                                                      let i = 0;
                                                      i <
                                                      tempAvailableSlots.length;
                                                      i += 1
                                                    ) {
                                                      tempAvailableSlots[i] = {
                                                        ...tempAvailableSlots[
                                                          i
                                                        ],
                                                        selected: false
                                                      };
                                                      if (
                                                        tempAvailableSlots[i]
                                                          .slot.id === slot.id
                                                      ) {
                                                        tempAvailableSlots[
                                                          i
                                                        ].selected = true;
                                                        selectedIndex = i;
                                                      }
                                                    }
                                                    setAvailableSlots(
                                                      tempAvailableSlots
                                                    );
                                                    setSlotForReschedule(
                                                      tempAvailableSlots[
                                                        selectedIndex
                                                      ]
                                                    );
                                                  }
                                                : null
                                            }
                                          >
                                            <div
                                              className={`time-slot${
                                                selected ? ' selected-slot' : ''
                                              }${
                                                available
                                                  ? ''
                                                  : ' disabled-slot'
                                              }`}
                                            >
                                              <p>{slot.name.toUpperCase()}</p>
                                            </div>
                                          </Col>
                                        );
                                      })}
                              </Row>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="book-select-options">
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px'
                          }}
                        >
                          <Select
                            className="book-now-selection"
                            placeholder="Select patient"
                            options={patientsSelectData}
                            value={changedPatientID}
                            onChange={(change) => {
                              setChangedPatientID(change);
                            }}
                          />
                          <Select
                            className="book-now-selection"
                            placeholder="Select lab"
                            options={labsOption}
                            value={selectedLabId}
                            onChange={(change) => {
                              setSelectedLabId(change);
                              calculateProfitPerOrder(change);
                            }}
                          />
                        </div>
                        <Select
                          className="book-now-selection"
                          placeholder="Select address"
                          options={addressOptions}
                          value={changedAddressID}
                          onChange={(change) => {
                            setChangedAddressID(change);
                          }}
                        />
                      </div>
                      <div className="bottom-container">
                        <div className="pricing-breakdown">
                          <div className="pricing-left">
                            <span>ACTUAL PRICE:</span>
                            <span>DELIVERY:</span>
                            <span>COUPON:</span>
                            <span>COUPON DISCOUNT:</span>
                            <span>CONVENIENCE FEE:</span>
                            <b>
                              <span>TOTAL AMOUNT:</span>
                            </b>
                            <span>COINS USED:</span>
                            <b>
                              <span>PAYABLE AMOUNT:</span>
                            </b>
                          </div>
                          <div className="pricing-right">
                            <span>
                              ₹ {parseFloat(totalTestPrice - totalDiscountAmt)}{' '}
                              &nbsp;
                              <strike>₹{parseFloat(totalTestPrice)}</strike>
                            </span>
                            <span>₹ {cartDetails.deliveryCharges}</span>
                            <span>
                              {!cartDetails.offerApplied
                                ? 'Not applied'
                                : cartDetails.offerApplied.couponCode}
                            </span>
                            <span>
                              {!cartDetails.offerApplied
                                ? '-'
                                : `₹ ${parseFloat(
                                    totalTestPrice -
                                      totalDiscountAmt -
                                      cartDetails.subTotal +
                                      cartDetails.deliveryCharges
                                  ).toFixed(1)}`}
                            </span>
                            <span>₹ {cartDetails.convenienceFees}</span>
                            <b>
                              <span>
                                ₹
                                {cartDetails.subTotal +
                                  (cartDetails.coinsApplied || 0)}
                              </span>
                            </b>
                            <span>₹ {cartDetails.coinsApplied}</span>
                            <b>
                              <span>₹ {cartDetails.subTotal}</span>
                            </b>
                          </div>
                        </div>
                        <div className="pay-check-container">
                          <Checkbox
                            checked={isOrderPaid}
                            onChange={(e) => {
                              setIsOrderPaid(e.target.checked);
                              setPaidValue('CASH');
                            }}
                          >
                            Is order paid?
                          </Checkbox>

                          {isOrderPaid && (
                            <Radio.Group
                              onChange={(e) => {
                                setPaidValue(e.target.value);
                              }}
                              value={paidValue}
                            >
                              <Radio value="CASH">Cash</Radio>
                              <Radio value="ONLINE">Online</Radio>
                            </Radio.Group>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        {profitPerOrder && (
                          <div className="profit-per-order">
                            <span>
                              PROFIT PER ORDER :&nbsp;
                              {isVisible ? profitPerOrder : 'XXXX'}
                            </span>
                            <span
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              {isVisible ? (
                                <BsEyeFill
                                  onClick={handleVisibleValue}
                                  size={20}
                                />
                              ) : (
                                <BsEyeSlashFill
                                  onClick={handleVisibleValue}
                                  size={20}
                                />
                              )}
                            </span>
                          </div>
                        )}
                        <Button
                          loading={isBookingOrder}
                          disabled={!slotForReschedule}
                          className="book-btn"
                          onClick={async () => {
                            if (!changedPatientID) {
                              message.error('Please select required patient');
                              return;
                            }
                            if (!selectedLabId) {
                              message.error('Please select required lab');
                              return;
                            }
                            if (!changedAddressID) {
                              message.error('Please select required address');
                              return;
                            }

                            const key = 'updatable';
                            message.open({
                              key,
                              type: 'loading',
                              content: 'Booking order'
                            });
                            setIsBookingOrder(true);
                            const { id } = slotForReschedule.slot;
                            const customerId = searchParams.get('customerId');
                            const formatted = getDateIntoDDMMYYYY(currentDate);
                            const data = {
                              slotId: id,
                              selectedDate: formatted,
                              patientId: changedPatientID,
                              addressId: changedAddressID,
                              customerId: customerId,
                              labId: selectedLabId,
                              paid: isOrderPaid ? paidValue : null
                            };

                            await placeOrderAsAdmin(data);
                            fetchCustomerById(customerId);
                            handleCancelBooking();
                            setCartDetails([]);
                            setSelectedTests([]);
                            setIsVisible(false);
                            setIsBookingOrder(false);
                            message.open({
                              key,
                              type: 'success',
                              content: 'Order booked!'
                            });
                            message.destroy(key);
                          }}
                        >
                          Book Now
                        </Button>
                      </div>
                    </div>
                  )}
                </Modal>
              </div>
            </Col>
            <Col span={6}>
              <div className="card user-basic-form">
                <div className="select-tests">
                  <span className="cart-title">
                    <span className="title-container">
                      <b>CART</b>
                      <span className="selected-test-number">
                        <b> {selectedTests.length}</b>
                      </span>
                    </span>

                    <Popconfirm
                      title="Clear"
                      description="Are you sure to clear the cart?"
                      onConfirm={async () => {
                        const key = 'updatable';
                        message.open({
                          key,
                          type: 'loading',
                          content: 'Clearing'
                        });
                        await clearCustomerCart(cartId);
                        setCartDetails([]);
                        setSelectedTests([]);

                        setSaveUpdate(false);
                        message.open({
                          key,
                          type: 'success',
                          content: 'Cleared'
                        });
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="text" className="cancel-cart">
                        Clear
                      </Button>
                    </Popconfirm>
                    {/* <CloseCircleOutlined className="cancel-cart" /> */}
                  </span>
                  <div className="search-tests-patients">
                    <SearchComponent
                      name="Tests"
                      debounceTime={DEBOUNCE_TIME}
                      getData={fetchLiveTests}
                    />
                    {!isFetchingTests && showingTestResults && (
                      <div className="search-result" id="test-searcher">
                        {fetchedTests.length <= 0 ? (
                          <Empty />
                        ) : (
                          fetchedTests.map((currentTest) => {
                            const { isSelected, test } = currentTest;
                            return (
                              <div className="tests-list" key={test.id}>
                                <Typography.Text>{test.title}</Typography.Text>
                                {isSelected ? (
                                  <Button
                                    type="text"
                                    onClick={() => {
                                      const tests = selectedTests.slice();
                                      for (
                                        let i = 0;
                                        i < tests.length;
                                        i += 1
                                      ) {
                                        if (tests.length === 1) {
                                          if (tests[i].id === test.id) {
                                            tests.splice(i, 1);
                                            setSaveUpdate(false);
                                          }
                                        } else if (tests[i].id === test.id) {
                                          tests.splice(i, 1);
                                        }
                                      }
                                      const tempFetchedTests =
                                        fetchedTests.slice();
                                      for (
                                        let i = 0;
                                        i < tempFetchedTests.length;
                                        i += 1
                                      ) {
                                        if (
                                          tempFetchedTests[i].test.id ===
                                          test.id
                                        ) {
                                          tempFetchedTests[i] = {
                                            ...tempFetchedTests[i],
                                            isSelected: false
                                          };
                                          break;
                                        }
                                      }
                                      setSelectedTests(tests);
                                      setFetchedTests(tempFetchedTests);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      const tests = selectedTests.slice();
                                      tests.push(test);
                                      const tempFetchedTests =
                                        fetchedTests.slice();
                                      for (
                                        let i = 0;
                                        i < tempFetchedTests.length;
                                        i += 1
                                      ) {
                                        if (
                                          tempFetchedTests[i].test.id ===
                                          test.id
                                        ) {
                                          tempFetchedTests[i] = {
                                            ...tempFetchedTests[i],
                                            isSelected: true
                                          };
                                          break;
                                        }
                                      }
                                      setSelectedTests(tests);
                                      setFetchedTests(tempFetchedTests);
                                      setSaveUpdate(true);
                                    }}
                                    type="default"
                                  >
                                    Add
                                  </Button>
                                )}
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                  </div>
                  <div className="selected-tests-patients-container">
                    {selectedTests.length <= 0 ? (
                      <Empty />
                    ) : (
                      selectedTests.map((currentTest) => {
                        const { id, title } = currentTest;
                        return (
                          <div className="tests-list" key={id}>
                            <Typography.Text>{title}</Typography.Text>
                            <Button
                              type="text"
                              onClick={async () => {
                                const tests = selectedTests.slice();
                                const index = selectedTests.findIndex(
                                  (test) => test.id === id
                                );
                                if (!(tests.length === 1)) {
                                  tests.splice(index, 1);
                                  setSelectedTests(tests);
                                  setCartDetails([]);
                                  setSaveUpdate(true);
                                } else {
                                  clearCustomerCart(cartId);
                                  setCartDetails([]);
                                  setSelectedTests([]);
                                  setSaveUpdate(false);
                                }
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div className="add-cart">
                    <Button
                      disabled={selectedTests.length <= 0}
                      loading={savingTests}
                      onClick={saveAddedCartTests}
                    >
                      Save
                    </Button>
                  </div>
                  {saveUpdate ? (
                    <p className="warning-note">
                      Please save to proceed further!
                    </p>
                  ) : null}
                  {selectedTests.length === 0 || saveUpdate ? null : (
                    <div className="code-and-coins">
                      <Select
                        onDropdownVisibleChange={(change) => {
                          const customerId = searchParams.get('customerId');
                          if (change) {
                            fetchCouponsAsAdmin(customerId);
                          }
                        }}
                        defaultValue={cartDetails?.offerApplied?.couponCode}
                        disabled={selectedTests.length <= 0 || saveUpdate}
                        placeholder="Apply Coupon"
                        className="admin-select-coupon"
                        onChange={async (id) => {
                          const customerId = searchParams.get('customerId');
                          const key = 'updatable';
                          message.open({
                            key,
                            type: 'loading',
                            content: 'Applying coupon...'
                          });
                          try {
                            await applyOfferAsAdmin(id, customerId);
                            getCustomerCartDetails(customerId);
                            message.open({
                              key,
                              type: 'success',
                              content: 'Applied'
                            });
                          } catch (error) {
                            message.error(error);
                          }
                        }}
                      >
                        {offerCoupons?.length > 0 &&
                          offerCoupons.map((options) => (
                            <Select.Option key={options.id}>
                              {options.couponCode}
                            </Select.Option>
                          ))}
                      </Select>
                      <div>
                        <Checkbox
                          checked={cartDetails.usedCoins}
                          // eslint-disable-next-line eqeqeq
                          disabled={form.getFieldValue('coinBalance') == 0}
                          onChange={async () => {
                            const customerId = searchParams.get('customerId');
                            const key = 'updatable';
                            message.open({
                              key,
                              type: 'loading',
                              content: 'Processing...'
                            });
                            await toggleUseCoinsAsAdmin(customerId);
                            await fetchCustomerById(customerId);
                            getCustomerCartDetails(customerId);
                            message.open({
                              key,
                              type: 'success',
                              content: 'Done'
                            });
                          }}
                        >
                          Use Coins
                        </Checkbox>
                        <Checkbox
                          checked={cartDetails.deliverReport}
                          onChange={async () => {
                            const customerId = searchParams.get('customerId');
                            const key = 'updatable';
                            message.open({
                              key,
                              type: 'loading',
                              content: 'Processing...'
                            });
                            await toggleDeliveryAtHomeAsAdmin(customerId);
                            await fetchCustomerById(customerId);
                            getCustomerCartDetails(customerId);
                            message.open({
                              key,
                              type: 'success',
                              content: 'Done'
                            });
                          }}
                        >
                          Deliver Report
                        </Checkbox>
                      </div>
                    </div>
                  )}

                  <div className="book-now-btn">
                    {selectedTests.length === 0 || saveUpdate ? null : (
                      <Button
                        disabled={saveUpdate || !selectedTests.length > 0}
                        onClick={async () => {
                          const customerId = searchParams.get('customerId');
                          // FETCHING ADDRESS & LABS
                          await fetchLabsByCart(customerId);
                          fetchAddress(customerId);
                          // FETCHING PATIENTS
                          fetchPatient(customerId);
                          if (saveUpdate) {
                            await saveAddedCartTests();
                          }
                          const now = new Date();
                          monitorSlots(now);
                          setCurrentDate(now);
                          setRefetchingAvailableSlots(true);
                          setBookNowModal(true);
                        }}
                      >
                        Book Now
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: '24px'
                }}
              />
              <div className="card user-basic-form">
                <span>
                  Joining date -
                  <b>
                    {fullDateFormat(new Date(customer.createdAt))},{' '}
                    {dayjs(customer.createdAt).format('hh:mm a')}
                  </b>
                </span>
                <div className="referral-code-wrapper">
                  <span className="referral-title">Referral code</span>
                  <br />
                  <Space className="referral-code-container">
                    {customer.userReferenceId}
                    <Copy
                      className="copy-btn"
                      onClick={() => copyToClipboard(customer.userReferenceId)}
                    />
                  </Space>
                </div>
                <Divider />
                <div className="disable-user-wrapper">
                  <span className="enable-disable-title">
                    Enable/Disable to restrict customers from using the app
                  </span>
                  <br />
                  {customer.isDisabled ? (
                    <Button
                      onClick={() => disableEnableCustomer(customer)}
                      type="default"
                      loading={isChangingAccountState}
                      className="enable-btn"
                    >
                      Enable account
                    </Button>
                  ) : (
                    <Button
                      onClick={() => disableEnableCustomer(customer)}
                      type="default"
                      className="disable-btn"
                      loading={isChangingAccountState}
                    >
                      Disable account
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}
export default CustomerDetail;
